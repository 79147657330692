import { PropsWithChildren, useEffect } from "react";
import { useParams } from "react-router-dom";
import { loadWithCode } from "store/slices/application";
import { useDispatch } from "store";
import { reset } from "store/slices/intake";
import { useApplication } from "hooks";

const ApplicationLoader = ({ children }: PropsWithChildren) => {
	const { code } = useParams<{code: string }>();
	const { loading, application, isStale } = useApplication();
	const dispatch = useDispatch();

	useEffect(() => {
		if (!code || application.code === code || loading) return;

		dispatch(loadWithCode(code));
	}, [code, application.code, loading, isStale]);

	useEffect(() => {
		dispatch(reset());
	}, []);
	
	return loading
		? <div>loading...</div>
		: <>{children}</>;
};

export { ApplicationLoader };
