import React from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Card, CardContent, CardHeader, Divider, Typography, CardProps, CardHeaderProps, CardContentProps } from "@mui/material";

// project imports
import { KeyedObject } from "types";

// constant
const headerSX = {
	"& .MuiCardHeader-action": { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

export interface MainCardProps extends KeyedObject {
    border?: boolean;
    boxShadow?: boolean;
    children: React.ReactNode | string;
    style?: React.CSSProperties;
    content?: boolean;
    className?: string;
    contentClass?: string;
    contentSX?: CardContentProps["sx"];
    darkTitle?: boolean;
    sx?: CardProps["sx"];
    secondary?: CardHeaderProps["action"];
    shadow?: number | string;
    elevation?: number;
    title?: React.ReactNode | string;
}

const MainCard = (
	(
		{
			border = false,
			boxShadow,
			children,
			content = true,
			contentClass = "",
			contentSX = {},
			darkTitle,
			secondary,
			shadow,
			sx = {},
			title,
			...others
		}: MainCardProps
	) => {
		const theme = useTheme();

		return (
			<Card
				{...others}
				sx={{
					border: border ? "1px solid" : "none",
					borderColor: theme.palette.mode === "dark" ? theme.palette.background.default : theme.palette.grey[300] + 98,
					":hover": {
						boxShadow: boxShadow
							? shadow ||
                              (theme.palette.mode === "dark" ? "0 2px 14px 0 rgb(33 150 243 / 10%)" : "0 2px 14px 0 rgb(32 40 45 / 8%)")
							: "inherit"
					},
					...sx
				}}
			>
				{/* card header and action */}
				{!darkTitle && title && <CardHeader sx={headerSX} title={title} action={secondary} />}
				{darkTitle && title && (
					<CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
				)}

				{/* content & header divider */}
				{title && <Divider />}

				{/* card content */}
				{content && (
					<CardContent sx={contentSX} className={contentClass}>
						{children}
					</CardContent>
				)}
				{!content && children}
			</Card>
		);
	}
);

export default (MainCard);
