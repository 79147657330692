import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "library/api";

interface IOfferAgreement {
	url: string;
	filename: string;
}

const downloadOfferAgreement = createAsyncThunk("application/downloadOfferAgreement", async (value: { code: string, fileName: string }) => {
	const file = await api.Applications.getFile(value.code, "offerAgreement", value.fileName);
	return {url: URL.createObjectURL(file.fileBlob), filename: file.fileName};
});

const updateOffer = createAsyncThunk("application/updateOffer", async (value: { code: string, accepted: boolean }) => {
	return await api.Applications.updateOffer(value.code, value.accepted);
});

interface IOffer {
	loading: boolean;
	agreement: IOfferAgreement | undefined
}

const initialState: IOffer = {
	loading: false,
	agreement: undefined
};

const offer = createSlice(({
	name: "offerState",
	initialState,
	reducers :{},
	extraReducers: builder => {
		builder.addCase(downloadOfferAgreement.pending, (state) => ({...state, loading: true}));
		builder.addCase(downloadOfferAgreement.fulfilled, (state, {payload}) => ({...state, loading: false, agreement: payload}));
		builder.addCase(updateOffer.pending, (state) => ({...state, loading: true, agreement: undefined}));
		builder.addCase(updateOffer.fulfilled, (state) => ({...state, loading: false}));
	}
}));

export default offer.reducer;

export {downloadOfferAgreement, updateOffer};
