const Routes = {
	tenantURL: "https://www.civida.ca",
	home: "/",
	dashboard: "/pages/dashboard",
	intakeApplication: "/pages/application",
};

const useIntakeRoutes = () => {
	return {routes: Routes};
};

export { useIntakeRoutes, Routes };