// third-party
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// project imports
import snackbarReducer from "./slices/snackbar";
import customerReducer from "./slices/customer";
import contactReducer from "./slices/contact";
import productReducer from "./slices/product";
import chatReducer from "./slices/chat";
import calendarReducer from "./slices/calendar";
import mailReducer from "./slices/mail";
import userReducer from "./slices/user";
import cartReducer from "./slices/cart";
import kanbanReducer from "./slices/kanban";
import menuReducer from "./slices/menu";
import intakeReducer from "./slices/intake";
import applicationReducer from "./slices/application";
import annualReviewReducer from "./slices/annualReview";
import interimReviewReducer from "./slices/interimReview";
import submissionReducer from "./slices/submission";
import incomeTypesReducer from "./slices/incomeTypes";
import housingBodiesReducer from "./slices/housingBodies";
import announcementsReducer from "./slices/announcements";
import notificationsReducer from "./slices/notifications";
import programsReducer from "./slices/programs";
import addressLookupReducer from "./slices/addressLookup";
import registrationReducer from "./slices/registration";
import offerReducer from "./slices/offer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
	snackbar: snackbarReducer,
	cart: persistReducer(
		{
			key: "cart",
			storage,
			keyPrefix: "berry-"
		},
		cartReducer
	),
	kanban: kanbanReducer,
	customer: customerReducer,
	contact: contactReducer,
	product: productReducer,
	chat: chatReducer,
	calendar: calendarReducer,
	mail: mailReducer,
	user: userReducer,
	menu: menuReducer,
	intake: intakeReducer,
	application: applicationReducer,
	annualReview: annualReviewReducer,
	interimReview: interimReviewReducer,
	submission: submissionReducer,
	housingBodies: housingBodiesReducer,
	incomeTypes: incomeTypesReducer,
	announcements: announcementsReducer,
	notifications: notificationsReducer,
	programs: programsReducer,
	addressLookup: addressLookupReducer,
	registration: registrationReducer,
	offer: offerReducer,
});

export default reducer;