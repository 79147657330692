import { ApplicationType } from "./enums";

export type GridWidth = {
    xs: number;
    sm: number;
    md: number;
}

export const oneThirdWidth: GridWidth = {
	xs: 12,
	sm: 12,
	md: 4
};

export const oneQuarterWidth: GridWidth = {
	xs: 12,
	sm: 12,
	md: 3
};


export const halfWidth: GridWidth = {
	xs: 12,
	sm: 12,
	md: 6
};

export const twoThirdWidth: GridWidth = {
	xs: 12,
	sm: 12,
	md: 8
};

export const fullWidth: GridWidth = {
	xs: 12,
	sm: 12,
	md: 12
};

export const controlMinWidth = 250;

export const isEmpty = (value: object | [] | undefined) => !value || !Object.keys(value).filter(k => k !== "validated").length;

export const hasAnyFalseyItem = (value: object | undefined) => {
	if (!value) return true;
	return Object.values(value).some((v) => !v);
};

export const isAllUndefinedOrNull = (value: object | any[]) => {
	if (!value) return true;

	if (Array.isArray(value)) return value.every((element) => element === undefined);

	return Object.values(value).every((v) => v === undefined || v === null);
};

export const hasProperty = (obj: object, prop: string) => Object.prototype.hasOwnProperty.call(obj, prop);

const sumNumber = (value: number): number => {
	return [...`${value}`]
		.map(c => parseInt(c))
		.reduce((x, y) => x + y);
};
export const isValidSin = (value: string): boolean => {
	if (!value?.match(/^[0-9 ]*$/))
		return false;

	const numbersOnly = [...value]
		.map(c => parseInt(c))
		.filter(v => isFinite(v));

	if (numbersOnly.length !== 9)
		return false;

	return !(numbersOnly
		.map((v, i) => {
			const product = v * (i % 2 + 1);
			return product < 10 ? product : sumNumber(product);
		})
		.reduce((x, y) => x + y) % 10);
};

export const isValidEmail = (value:string | undefined): boolean => {
	const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/i;
	return value?.match(emailRegex) != null;
};

export const ApplicationContent = {
	[ApplicationType.Standard]: {
		heading: "Application"
	},
	[ApplicationType.AnnualReview]: {
		heading: "Annual Review"
	},
	[ApplicationType.InterimReview]: {
		heading: ""
	}
};

export function CalculateAge(dateOfBirth: Date | undefined): number | undefined {
	if (!dateOfBirth) return undefined;
	const today = new Date();
	const month = today.getUTCMonth() - dateOfBirth.getUTCMonth();
	let age = today.getUTCFullYear() - dateOfBirth.getUTCFullYear();

	if (month < 0 || month === 0 && today.getUTCDate() < dateOfBirth.getUTCDate()) return --age;
	return age;
}