import {useDispatch, useSelector} from "store";
import {Application, ApplicationBuilder, ApplicationType, LoadStatus} from "library";
import {saveProgress, setApplication} from "store/slices/application";
import useNavigator from "./useNavigator";

type ApplicationStrategy = {
	type: ApplicationType;
	loading: boolean;
	isStale: boolean;
	application: ApplicationBuilder;
	mutations: {
		set: (payload: Partial<Application>) => void
		saveAsync: () => Promise<void>
	};
};

const useApplication = (): ApplicationStrategy => {
	const {status, builder: application, isStale} = useSelector(s => s.application);
	const navigate = useNavigator();
	const dispatch = useDispatch();

	if(status === LoadStatus.DoesNotExist) {
		navigate("/pages/dashboard", { relative: "path" });
	}

	return {
		type: application.type,
		loading: status === LoadStatus.Loading,
		isStale: isStale,
		application: application,
		mutations: {
			set: (payload) => dispatch(setApplication(payload)),
			saveAsync: async () => void await dispatch(saveProgress({})),
		}
	};
};

export {useApplication};